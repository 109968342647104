import { useApi } from '~/composables/useApi'

export default defineNuxtRouteMiddleware(async () => {
	const route = useRoute()

	const { userApplication } = await useApi(`/applications/${route.params.applicationId}/user-role`)
	if (userApplication.role !== UserApplicationRole.ADMIN) {
		return navigateTo('/404')
	}
})
